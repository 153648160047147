import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
//import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import logo from "images/logo.png";
import CustomizeIconImage from "images/customize-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <MainFeature1
        subheading={<Subheading>About BlockSure</Subheading>}
        heading="BlockChain Medical Records "
        buttonRounded={false}
        primaryButtonText="Sign Up Now"
        imageSrc={logo}
        description = "BlockSure is an innovative Canadian startup looking to utilize blockchain technology to create an electronic health record (EHR) platform that provides medical organizations and its patients with the ability to have convenient access to the patient’s medical records. The company is looking to create a safe and secure platform using a blockchain-based system where its users are able to upload and view a patient’s health records which allows medical organizations to serve their patients more efficiently. BlockSure hopes that the development of its platform will help enhance the interactions and experiences that patients have with medical organizations through the use of modern blockchain technology."
      />
      <Features 
        heading="Why you should choose us!"
        description="Our goal is for you to have the ability to participate in your own healthcare."
        cards={[
          {
            imageSrc: CustomizeIconImage,
            title: "Customize who has access",
            description: "Authorize access to let clinics and other doctors access your records, reducing the amount of time you have to wait for a doctors opinion."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Blockchain Security",
            description: "Currently medical systems are regularly attacked by hackers, our blockchain system will give you a backup of your medical records and store the information securely."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Easy to use",
            description: "A easy to use system that reduces the amount of time clinics have to spend to upload documents, making it process easier and more efficient for clinics and patients."
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};
