import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.png";

import { Subheading as SubheadingBase } from "components/misc/Headings.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center underline`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <Subheading>
            <a href="mailto:zahra.ramezani@blocksure.ca">
              zahra.ramezani@blocksure.ca
            </a>
          </Subheading>
          <Subheading>
            <a href="tel:6472371361">
              Tel: 6472371361
            </a>
          </Subheading>
          <Subheading>
            <a href="https://www.linkedin.com/company/blocksure-ca/">
              LinkedIn
            </a>
          </Subheading>
          <br />
          <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#features">Features</Link>
            <Link href="#about">About Us</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#testimonials">Testimonials</Link>
            <Link href="#contact">Contact Us</Link>
            <Link href="PrivacyPolicy">Privacy Policy</Link>
            <Link href="TermsAndCond">Terms and Conditions</Link>
          </LinksContainer>

          {/* <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/company/block-sure">
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/blocksure_/?hl=en">
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </SocialLink>
          </SocialLinksContainer> */}

          {/*
            <CopyrightText>
            &copy; Copyright 2020, Treact Inc. All Rights Reserved.
          </CopyrightText>
            */}
        </Row>
      </Content>
    </Container>
  );
};
