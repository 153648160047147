import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";
import AboutUsPage from "pages/AboutUs.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/MiniCenteredFooter.js";

 
import ShieldIconImage from "images/shield-icon.svg"; 
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg"; 

export default () => {
  return (
    <div>
     
      <Hero />
      <div id='features'>
        <Features
          heading="Key Features "
          description="Our goal is to make it possible for you to live the best life without worrying.  "
          cards={[
            {
              imageSrc: ShieldIconImage,
              title: "Security",
              description: "Blockchain technology ensures that electronic health records are safe and secure once they’re uploaded."
            },
            {
              imageSrc: ReliableIconImage,
              title: "24/7 access",
              description: "Users will have the ability to access and/or grant medical organizations access to their health records at any time."
            },
            {
              imageSrc: FastIconImage,
              title: "Time Efficient",
              description: "The platform allows for fast medical record updates, making it so records are distributed almost immediately."
            },
            
            
          ]}
          linkText=""
        />
      </div>
      <div id='about'>
        <AboutUsPage/>
      </div>
      <div id='pricing'>
        <Pricing />
      </div>
      <div id='testimonials'>
        <Testimonial />
      </div>
      <div id='faq'>
        <FAQ />
      </div>
      <div id='contact'> 
        <ContactUsForm/>
      </div>
      <Footer />
    </div>
  );
}
